import React from "react";
import "./FraudRecovery.css";
import icon1 from "../../media/img/FraudRecovery/icon1.svg";
import icon2 from "../../media/img/FraudRecovery/icon2.svg";
import icon3 from "../../media/img/FraudRecovery/icon3.svg";
import icon4 from "../../media/img/FraudRecovery/icon4.svg";
import { useEffect } from "react";
import Image from "react-image-webp";

const FraudRecovery = (props) => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    console.log(currentYear);
  }, []);
  return (
    <div className="fraud-recovery-container">
      <div className="fraud-recovery-images">
        <div className="img-1-border"></div>
        <Image
          className="img-1"
          src={props.img1.src}
          webp={props.img1.webp}
          alt={props.img1.alt}
          width={props.img1.width}
          height={props.img1.height}
        ></Image>

        <div className="img-2-border"></div>

        <Image
          className="img-2"
          src={props.img2.src}
          webp={props.img2.webp}
          alt={props.img2.alt}
          width={props.img2.width}
          height={props.img2.height}
        ></Image>

        {props.isIndex ? (
          <div className="is-index-container">
            <h5>{currentYear - 2018}+</h5>
            <h6>Years of Expereience</h6>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="fraud-recovery-text-container">
        <h2 className="fraud-recovery-title">
          Golden Recovery: Leaders In Financial <span> Fraud Recovery</span>
        </h2>
        <div className="icons-container">
          <div className="icon-container">
            <img src={icon1} alt="" />
            <div className="icon-text">
              <h5>24/7 Availability</h5>
              <p>
                Our call center is staffed all the time because we know scammers
                never rest.
              </p>
            </div>
          </div>
          <div className="icon-container">
            <img src={icon2} alt="" />
            <div className="icon-text">
              <h5>Expert Advisors</h5>
              <p>
                Our staff is comprised of some of the most talented agents in
                the industry and they are ready to help you.
              </p>
            </div>
          </div>
          <div className="icon-container">
            <img src={icon3} alt="" />
            <div className="icon-text">
              <h5>Fast Resolution</h5>
              <p>
                Our support staff will work tirelessly around the clock to
                achieve a speedy resolution you require
              </p>
            </div>
          </div>
          <div className="icon-container">
            <img src={icon4} alt="" />
            <div className="icon-text">
              <h5>Peace of Mind</h5>
              <p>
                We will do the fighting so you can rest. They have taken enough
                from you let us get it back.
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              window.location.href = "https://golden-recovery.com/about";
            }}
          >
            Explore More
          </button>
        </div>
      </div>
    </div>
  );
};

export default FraudRecovery;
