import { useState, useEffect } from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Recaptcha from "react-google-recaptcha";
import FraudRecovery from '../src/components/FraudRecovery/FraudRecovery'
import stocks from "./media/img/FraudRecovery/stocks.png";
import stocksWebp from "./media/webp/FraudRecovery/stocks.webp";
import serviceIcon1 from "./media/img/About/icon1.svg";
import serviceIcon2 from "./media/img/About/icon2.svg";
import serviceIcon3 from "./media/img/About/icon3.svg";
import laptoping from "./media/img/FraudRecovery/laptoping.png";
import laptopingWebp from "./media/webp/FraudRecovery/laptoping.webp";
import visa from "./media/img/visa_logo2.svg";
import trustPilot from "./media/img/trustpilot_logo2.svg";
import globalD from "./media/img/gd2.svg";
import client1vid from "./media/video/client1.mp4";
import client2vid from "./media/video/client2.mp4";
import client3vid from "./media/video/client3.mp4";
import logo from './media/img/logo-dark.png'
import logoWebp from './media/webp/logo-dark.webp'
import Image from "react-image-webp";
import handshake from "./media/img/handshake.svg"

import padlock from "./media/img/padlock.png";
import gdpr from "./media/img/gdpr.png";
const fraudRecoveryElements = [
  {
    src: stocks,
    webp: stocksWebp,
    alt: "Finger pointing at a stock graph, stocks going up, electricity",
    width: 157,
    height: 157,
  },
  {
    src: laptoping,
    webp: laptopingWebp,
    alt: "Man in suit typing on a laptop with an overlay of red and green graphs",
    width: 157,
    height: 157,
  },
];
function App() {
  const [Countries, setCountries] = useState([]);
  const [Phone, setPhone] = useState();
  const [Country, setCountry] = useState();
  const [ButtonDisabled, setButtonDisabled] = useState(true);
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [AmountLost, setAmountLost] = useState("");
  const [Description, setDescription] = useState("");
  function enableButton() {
    console.log("enabled")
    setButtonDisabled(false);
  }
  const [Loading, setLoading] = useState(false);
  const [FormSent, setFormSent] = useState(false);
  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((res) => res.json())
      .then((json) => setCountries(json));
    console.log(Countries);
  }, []);
  function sendForm() {
    setLoading(true);
    let url = "https://hooks.zapier.com/hooks/catch/11547837/bsv8ksx/";
    let requestBody = {
      FullName: FullName,
      Email: Email,
      Phone: Phone,
      AmountLost: `${AmountLost}$`,
      Description: Description,
      Country: Country,
    };
    let requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify(requestBody),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setLoading(false);
        setFormSent(true);
        setPhone("");
        setFullName("");
        setEmail("");
        setAmountLost("");
        setDescription("");
      });
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <section className='form-section'>
              <Image
                className="logo"
                src={logo}
                webp={logoWebp}
                alt={"Logo"}
                width={350}
                height={325}
              ></Image>
              <form id="top" action="/thank-you"
                onSubmit={() => {
                  sendForm();
                  return false;
                }}>
                <h1>GET YOUR MONEY BACK FROM ANY SCAM</h1>
                <h2>FIND OUT HOW TO GET YOUR MONEY BACK TODAY</h2>
                <input
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  value={FullName}
                  required
                  placeholder="Full Name"
                ></input>
                <label for="honeypot">honeypot</label>
                <input id='honeypot' name='honeypot' size="40" type="text"></input>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={Email}
                  required
                  placeholder="Email Address"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                ></input>
                <PhoneInput
                  required
                  maxLength="16"
                  placeholder="Phone"
                  value={Phone}
                  onChange={setPhone}
                  onSelect={(e) => {
                    console.log(e.target);
                  }}
                />
                <select
                  required
                  onClick={(e) => {
                    if (e.target.value != "Country") setCountry(e.target.value);
                  }}
                >
                  <option disabled selected>
                    Country
                  </option>
                  {Countries &&
                    Countries.map((country) => (
                      <option key={country.name.common}>{country.name.common}</option>
                    ))}
                </select>
                {/* 
          <input
            onChange={(e) => {
              setAmountLost(e.target.value);
            }}
            value={AmountLost}
            placeholder="How much did you lose? (USD)"
            type="number"
          /> */}
                <select
                  onChange={(e) => {
                    setAmountLost(e.target.value);
                  }}
                  required
                >
                  <option selected disabled>
                    How much did you lose? (USD)
                  </option>
                  <option>0-5K</option>
                  <option>5K-15K</option>
                  <option>15K-30K</option>
                  <option>30K+</option>
                </select>
                <select
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  required
                >
                  <option selected disabled>
                    Tell us about what happened to you
                  </option>
                  <option>Binary Scam</option>
                  <option>Cryptocurrency Scam</option>
                  <option>Forex Scam</option>
                  <option>Nigerian Scam</option>
                  <option>Stock Trading Scam</option>
                  <option>Online Banking Scam</option>
                </select>

                {Loading ? (
                  <></>
                ) : FormSent ? (
                  <p>Submitted! We will contact you as soon as possible</p>
                ) : (
                  <button
                    disabled={ButtonDisabled}
                    type="submit"
                    className="submit-btn"
                  >
                    Submit Post
                  </button>
                )}
                <div className='recaptcha'>
                  <Recaptcha

                    onChange={enableButton}
                    onloadCallback={() => {
                      console.log("disabled")
                      setButtonDisabled(true);
                      setFormSent(false);
                    }}
                    sitekey="6LeAneoeAAAAAHuInhQvWAUt47wyz7sA6dbn9hGK"


                  // test key
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  />
                </div>
                <p>You verify that this info is correct</p>
              </form>
              <Image
                className="logo"
                src={logo}
                webp={logoWebp}
                alt={"Logo"}
                width={350}
                height={325}
              ></Image>
            </section>
            <section className="trusted">
              <p>
                Golden-Recovery is an Official Partner of Moneyback Ltd (which trades
                as Payback) which is an Israeli company #515711653, authorized and
                regulated in Israel, and operates globally. In the UK, the company
                operates through a legal exclusion, which removes the need for
                Moneyback Ltd to be authorized and regulated by the FCA.
              </p>
              <div className='trusted-logos-parent'>
                <div className='trusted-logos-top'>
                  <img src={gdpr} alt=''></img>
                  <img src={padlock} alt=""></img>
                </div>
                <div className="trusted-logos">
                  <img src={visa} />
                  <img src={trustPilot} />
                  <img style={{ transform: 'translateY(-1.69em)' }} src={globalD} />
                </div>
              </div>
            </section>
            <section className='about-section'>
              <FraudRecovery img1={fraudRecoveryElements[0]} isIndex={true} img2={fraudRecoveryElements[1]} />
            </section>
            <section className='why-us-section'>
              <h2>
                Why Choose Us
              </h2>
              <div className='services'>
                <div className='service'>
                  <div>
                    <img src={serviceIcon1} />
                  </div>
                  <div>
                    <h5>1,023,100$</h5>
                    <h6>Money Returned</h6>
                  </div>
                </div>
                <div className='service'>
                  <div>
                    <img src={serviceIcon2} />
                  </div>
                  <div>
                    <h5>10,000+</h5>
                    <h6>Clients Helped</h6>
                  </div>
                </div>
                <div className='service'>
                  <div>
                    <img src={serviceIcon3} />
                  </div>
                  <div>
                    <h5>24/7</h5>
                    <h6>Available</h6>
                  </div>
                </div>
              </div>
            </section>
            <section className='testimonials-section'>
              <div className='testimonial'>
                <div className='video-container'>
                  <h2>Jacob</h2>
                  <video src={client1vid} controls />
                </div>
                <div className='review-container'>
                  <p>"</p>
                  <h3>15,000 USD</h3>
                  <h4>REFUND AMOUNT</h4>
                  <p>"</p>

                </div>
              </div>
              <div className='testimonial inverted'>
                <div className='video-container'>
                  <h2>Michael</h2>
                  <video src={client2vid} controls />
                </div>
                <div className='review-container'>
                  <p>"</p>
                  <h3>26,000 USD</h3>
                  <h4>REFUND AMOUNT</h4>
                  <p>"</p>

                </div>
              </div>
              <div className='testimonial'>
                <div className='video-container'>
                  <h2>Ronkej</h2>
                  <video src={client3vid} controls />
                </div>
                <div className='review-container'>
                  <p>"</p>
                  <h3>12,000 USD</h3>
                  <h4>REFUND AMOUNT</h4>
                  <p>"</p>

                </div>
              </div>
            </section>
            <section className='button-section'>
              <button onClick={() => {
                window.location.href = "#top"
              }}>GET FREE CONSULTATION</button>
            </section>

          </div>
        </Route>
        <Route exact path="/thank-you">
          <section className='thank-you-page'>
            <div className='image-overlay' />
            <img className='handshake' width={50} height={50} src={handshake} alt='Handshake icon' />
            <div className='text'>
              <h1>Thank you for reaching out</h1>
              <h3>One Of Our Experts Will Get In Touch With You Shortly.</h3>
              <h3>For More Information About Our Services Please Press Here</h3></div>
            <button onClick={() => {
              window.location.href = "https://www.golden-recovery.com"
            }}>OUR WEBSITE</button>
          </section>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
